<template>
  <div>
    <ManageAlumniArticles />
  </div>
</template>
<script>
import ManageAlumniArticles from "@/components/ManageAlumniArticles/ManageAlumniArticles";
export default {
  components: {
    ManageAlumniArticles,
  },
  created() {},
};
</script>
